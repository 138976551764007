.AwardsAndHonors-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 10px;
}

.Awards-cards {
  display:flex;
  flex-direction: column;
  gap: 40px;
}

.Awards-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 25px;
  border-radius: 8px 0px 0px 0px;
  background: rgba(245, 252, 255, 1);
  border-radius: 8px;
  text-align:left;
  padding: 16px;
  height: auto;
  transform: translateY(20px);
  transition: all 0.5s ease-in;
}
.Awards-card:hover {
  opacity: 1;
  transform: translateY(0);
}
.AwardsTItle {
  font-weight: 600;
  font-size:15px;
}
.Trophy {
  color: rgba(237, 221, 43, 1);
  font-size: 70px;
}
.Awards-text {
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  max-width: fit-content;
}

@media (max-width: 768px) {
  .Awards-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Awards-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;

    gap: 25px;
    border-radius: 8px 0px 0px 0px;
    
    background: rgba(245, 252, 255, 1);
    border-radius: 8px;
    text-align: center;
  }
}
@media (max-width: 850px) {
  .Awards-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Awards-card {
    width: 350px;
  }
}
