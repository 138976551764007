.footer {
  background-color: #003366;
  width: 100%;
  height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
 
}



.social_media {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 400;
}
.icon_link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
 
}

.icon_link a {
  text-decoration: none;
  color: white;
 
  
}

.line {
  width: 80vw;
  margin-top: 10px;
}
.footer_text {
  margin-top: 10px;
}

@media (max-width: 768px) {
  .footer {
    font-size: 10px;
  }

  .social_media {
    flex-direction: row;
  }
}

@media (max-width: 480px) {
  .footer {
    font-size: 10px;
    height: 20vh;
    margin-top: 40px;
    
  }

  .icon_link {
    flex-direction: row;
    text-align: center;
  }
}
