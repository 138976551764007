.research-container-cardHome {
  width:100%;
  height: 70vh;
  padding: 26px 18px;
  gap: 30px;
  border-radius: 12px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: center;
  color: rgba(0, 52, 102, 1);
  transform: translateY(20px);
  transition: all 0.5s ease-in;
  text-align:left;
  background-color: aliceblue;
}
.research-container-cardHome:hover {
  opacity: 1;
  transform: translateY(0);
}

.research-card-desc-containerHome {
  font-size: 15px;
  font-weight: 600;
 
  padding: 0 10px;
}
@media (max-width: 1024px) {
  .research-container-cardHome{
    height: 80vh;
  }
}