.TrainingAndMembership-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}



  .counters-bg {
    background-color: #0e5397;
    padding: 40px 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
   
    width: 100%; /* Adds some margin from the edges */
    margin: 20px auto; 
    display: flex;
    flex-direction: column;
    gap:50px
}

.TrainingAndMembership-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.TrainingAndMembership-card {
  height: 50vh;
  width: 80%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(20px);
  transition: all 0.5s ease-in;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(245, 252, 255, 1);
  text-align: justify;

}


.TrainingAndMembership-card:hover {
  opacity: 1;
  transform: translateY(0);
}
/* .card-content {
  width: 100%;
  background-color: rgba(245, 252, 255, 1);
  padding: 17px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  /* min-height: 300px; */


.card-title {
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-weight: 600;

  text-align: center;
  margin-bottom: 10px;
}
.card-date {
  color: rgba(253, 111, 0, 1);
  font-size: 18px;
  font-weight: 600;
}

.card-text {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.card-location {
  font-weight: bold;
}
@media (max-width: 768px) {
  .TrainingAndMembership-cards {
    display: flex;
    flex-direction: column;
  }
  .card-date {
    font-size: 14px;
  }
}
@media (max-width: 1024px) {
  .TrainingAndMembership-cards {
    display: flex;
    flex-direction: column;
  }
}
