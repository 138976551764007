.research-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 10px;
  align-items: center;
}
.research-container-cards {
  display:flex;
 flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.research-container-card {
  width: 70%;
  min-height: 150px;
  padding: 26px 18px;
  gap: 10px;
  border-radius: 12px 0px 0px 0px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: center;
  color: rgba(0, 52, 102, 1);
  transform: translateY(20px);
  transition: all 0.5s ease-in;
  text-align:left;
  
  background-color: aliceblue;
}
.research-container-card:hover {
  opacity: 1;
  transform: translateY(0);
}

.research-card-title {
  color: rgba(0, 52, 102, 1);
  font-size: 15px;
  font-weight: 600;
 
}
.research-card-desc-container {
  width: 70%;
  /* min-height: 100px; */
  /* padding: 16px 0px 16px 0px; */
  gap: 10px;
  border-radius: 4px 0px 0px 0px;

}
.research-card-desc {
  font-size: 15px;
  font-weight: 600;
 
  padding: 0 10px;
}
.btns-container {
  display: flex;
}


@media (max-width: 768px) {
  .research-container-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
 
}
@media (max-width: 1024px) {
  .research-container-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .corner-circle-left {
    display: none;
  }
  .corner-circle-right {
    display: none;
  }
  .research-container-card {
    width: 380px;
  }
  .research-card-desc {
    font-size: 15px;
  }
  .research-card-desc-container {
    width: 366px;
  }
  .btns-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .researchCounter{
    display: flex;
    flex-direction: column;
  }
}
