.Counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Counter_style {
  font-size: 50px;
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}
.Counter_text {
  font-size: 12px;
  font-weight: 400;
  width: 12vw;
  text-align: center;
 
  
  
}
@media (max-width: 850px) {
  .Counter_style{
    font-size: 20px;
  }
  .Counter_text {
    font-size: 10px;
    font-weight: 400;
  }
}
