.Main_title {
  color: rgba(30, 30, 30, 1);
  font-size: 25px;
  font-weight: 600;
  /* line-height: 60px;
  letter-spacing: 0.03em; */
  text-align: center;
}
@media (max-width: 900px) {
 .Main_title{
  font-size: 15px;
 
  margin-top: 80px;
 
 }
}
