.About-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 10px;
}
.About_ImageSlider-container {
  width: 100%;
  background: rgba(245, 252, 255, 1);
  display: flex;
  align-items: center;
  height: 70vh;
}
.About_ImageSlider-container-inner {
  display: flex;
  align-items: center;
  gap: 70px;
  margin: 10px;
  justify-content: space-between;
  width: 100%;
}
.About_ImageSlider-container-right_side {
  width: 50%;
}
.AboutImageSlider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 90%;
}
.AboutSlide {
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #666;
  height: 40vh;
  width: 100%;
  border: 2px solid #fd6f00;
  object-fit: cover;
}
.slide-hidden {
  display: none;
}
.About_ImageSlider-container-left_side {
  height: 100%;
  width: 50%;
  margin: 40px;
}

.About_ImageSlider-container-doctor-name {
  color: #fd6f00;
  font-weight: 600;
  font-size: 25px;
}
.indicatorsAbout {
  display: flex;
  position: absolute;
  bottom: 250px;
}
.About_ImageSlider-container-text {
  font-size: 20px;
  max-width: 900px;
  text-align: justify;
}
.indicatorAbout {
  background-color: white;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}
.indicatorAbout-inactive {
  background-color: #4746466e;
}
.arrowAbout {
  position: absolute;
  height: 2rem;
  width: 2rem;
  color: white;
  filter: drop-shadow(0px 0px 5px #555);
}
.arrowAbout:hover {
  cursor: pointer;
}
.arrowAbout-left {
  top: 50%;
  right: 47%;
}
.arrowAbout-right {
  top: 50%;
  right: 3%;
}

.personalInfo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 50vh;
  gap: 30px;
}
.personalInfo-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.personalInfo-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.personalInfo-icon-box {
  width: 74px;
  height: 64px;
  padding: 8px 0px 0px 0px;
  gap: 10px;
  border-radius: 8px 0px 0px 0px;

  background-color: rgba(245, 252, 255, 1);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  display: flex;
  justify-content: center;
}
.personalInfo-icon-style {
  color: rgba(0, 52, 102, 1);
  height: 48px;
  width: 28px;
}
.personalInfo-text-style {
  font-size: 15px;
  font-weight: 500;
  line-height: 36px;
  color: rgba(98, 98, 98, 1);
}
.Education-container {
  width: 100%;
  height: 50vh;
}
ul.timeline-3 {
  list-style-type: none;
  position: relative;
}
ul.timeline-3:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline-3 > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline-3 > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #fd6f00;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.education-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.education-logo {
  width: 7vw;
  height: auto;
  margin-right: 15px;
}

.education-content {
  flex: 1;
}
.bg-custom-color {
  background-color: rgba(245, 252, 255, 1);
}
.par {
  text-align: justify;
}
.timelineAbout {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.timelineItem {
  display: flex;
  align-items: center;
  position: relative;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  /* transition: transform 0.3s ease;
  animation: customAni 2s ease 0s infinite normal none; */
}

.lineTimeAbout {
  width: 60px;
  height: 2px;
  background-color: #ccc;
}

.Language-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background-color: rgba(245, 252, 255, 1);
  width: 100%;
  height: 70vh;
}
.Language-container-parts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}
.language-measure {
  background: linear-gradient(0deg, #fd6f00, #fd6f00);
  width: 600px;
  height: 20.3px;
  border-radius: 50px;
}
.language-measure.good {
  background: linear-gradient(90deg, #fd6f00 60.13%, #a1bdd0 73.19%);
}

.Language-container-part1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.language-measurement-container {
  text-align: end;
}
.Language-name {
  font-size: 20px;
  font-weight: 400;
  line-height: 28.13px;
  letter-spacing: 0.05em;
  text-align: left;
}
.computerSkills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  gap: 30px;
}
.computerSkills-cards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.computerSkills-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.computerSkills-card-Circle {
  width: 6vw;
  height: 12vh;
  background-color: rgba(196, 196, 196, 0.4);
  border-radius: 50%;
  border: 5px solid rgba(59, 89, 153, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.computerSkills-card-Circle.latex {
  border-left: 5px solid white;
}
.computerSkills-text {
  color: rgba(84, 84, 84, 1);
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
}
.computerSkills-card-Circle-img {
  width: 3vw;
  height: 6vh;
  text-align: center;
}
.latex-icon {
  color: rgba(0, 128, 128, 1);
  text-align: center;
  width: 50px;
  height: 60px;
}
.computerSkills-card-Circle-img.office {
  width: 3vw;
  height: 5vh;
}
.ProfessionalWebPages-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 50vh;

  padding: 30px 0;
}

.ProfessionalWebPages-slider {
  width: 100%;
  height: 30vh;
  border: 2px solid #fd6f00;
  border-radius: 10px;
  padding: 10px;
  background: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.ProfessionalWebPages-cards1 {
  display: flex;
  justify-content: space-evenly;
  gap: 30px;
  align-items: center;
  transition: all 0.3s ease;
  width: 100%;
}

.ProfessionalWebPages-container-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 9vw;
  height: 9vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.ProfessionalWebPages-container-card:hover {
  transform: scale(1.05);
}

.logoImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.ProfessionalWebPages-container-card:hover .logoImage {
  transform: scale(1.1);
}

.arrows {
  display: flex;
  justify-content: space-between;
  width: 5vw;
}

.slider-arrow {
  font-size: 2rem;
  color: #fd6f00;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.slider-arrow:hover {
  transform: scale(1.2);
}
.ProfessionalWebPages-track {
  display: flex;
  gap: 30px; 
  width: 100%;
  align-items: center;
  justify-content: center; 
  animation: scroll 20s linear infinite;/* Allow the container to expand */
}


@media (max-width: 1100px) {
  .arrowAbout-left {
    top: 50%;
    right: 45%;
  }
  .arrowAbout-right {
    top: 50%;
    right: 5%;
  }

  .About_ImageSlider-container-text {
    font-size: 15px;
    max-width: 900px;
  }
  .About_ImageSlider-container-doctor-name {
    color: #fd6f00;
    font-weight: 600;
    font-size: 15px;
  }
  .Language-container-parts {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 300px) and (max-width: 900px) {
  .About_ImageSlider-container {
    height: 100vh;
    margin-bottom: 20px;
  }
  .AboutImageSlider {
    width: 100%;
  }
  .About_ImageSlider-container-inner {
    flex-direction: column;
    gap: 0px;
    margin-top: 180px;
  }
  .indicatorsAbout {
    display: none;
  }
  .arrowAbout-left {
    top: 100%;
    right: 80%;
  }
  .arrowAbout-right {
    top: 100%;
    right: 11%;
  }
  .About_ImageSlider-container-text {
    font-size: 10px;
  }
  .About_ImageSlider-container-doctor-name {
    color: #fd6f00;
    font-weight: 600;
    font-size: 12px;
  }
  .personalInfo-container {
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  .personalInfo-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
  }
  .personalInfo-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100px;
  }
  .personalInfo-icon-box {
    width: 40px;
    height: 40px;
  }
  .personalInfo-icon-style {
    color: rgba(0, 52, 102, 1);
    height: 30px;
    width: 20px;
  }
  .personalInfo-text-style {
    font-size: 8px;
  }
  .language-measure {
    width: 300px;
    height: 20.3px;
  }
  .Language-name {
    font-size: 15px;
  }
  .Language-container-parts {
    display: flex;
    flex-direction: column;
  }

  .indicatorsAbout {
    bottom: -72px;
  }
  .Education-container {
    height: 15vh;
  }
  .education-content h6 {
    font-size: 12px;
  }
  .par {
    font-size: 10px;
  }
  .computerSkills-container {
    height: 70vh;
  }
  .computerSkills-cards {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .ProfessionalWebPages-container {
    height: 100vh;
    gap: 30px;
  }
  .ProfessionalWebPages-cards1 {
    flex-direction: column;
  }
  .ProfessionalWebPages-slider {
    height: 100%;
  }

  .computerSkills-card-Circle {
    width: 20vw;
    height: 9vh;
  }
  .computerSkills-card-Circle.latex {
    border-left: 5px solid white;
  }
  .computerSkills-text {
    font-size: 15px;
  }
  .computerSkills-card-Circle-img {
    width: 50px;
    height: 30px;
    text-align: center;
    margin-top: 10px;
  }
  .latex-icon {
    color: rgba(0, 128, 128, 1);
    text-align: center;
    width: 50px;
    height: 60px;
  }
  .computerSkills-card-Circle-img.office {
    width: 30px;
    height: 30px;
  }
  .ProfessionalWebPages-container-card{
height: 10vw;
width: 10vw;
  }
  .ProfessionalWebPages-track {
    display: flex;
  flex-direction: column;
    width:100%; /* Allow the container to expand */
  }
}
