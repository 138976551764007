
.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.lineTime {
  width: 1px;
  height: 90px;

  border: 2px dashed rgba(52, 64, 84, 1);
}
.dashed_circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px dashed rgba(29, 41, 57, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .lineTime {
    width: 1px;
    height: 50px;
  
    border: 2px dashed rgba(52, 64, 84, 1);
  }
  .dashed_circle{
    width: 20px;
    height: 20px;
  }
  .circle{
    width: 20px;
    height: 20px;
  }
}