.mainContent-container {
  font-size: 20px;
  font-weight: 400;
  white-space: pre-wrap;
  text-align: justify;
}
@media (max-width: 768px) {
  .mainContent-container {
    font-size: 12px;
    font-weight: 400;
   
  }
}
@media (max-width: 1024px) and (min-width: 769px) {
  .mainContent-container {
    font-size: 12px;
    font-weight: 400;
   
  }
}
