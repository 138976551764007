.Experience-container {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-top: 10px;
}
.expImageSlider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
  width: 100%;
}

.slide {
  border-radius: 0.5rem;
  box-shadow: 0px 0px 7px #666;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slide-hidden {
  display: none;
}
.arrow {
  position: absolute;
  height: 2rem;
  width: 2rem;
  color: white;
  filter: drop-shadow(0px 0px 5px #555);
}
.arrow:hover {
  cursor: pointer;
}
.arrow-left {
  left: 1rem;
}
.arrow-right {
  right: 1rem;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 0rem;
}
.indicator {
  background-color: white;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}
.indicator-inactive {
  background-color: #4746466e;
}

.Experience-container-horizontal-cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.Experience-container-horizontal-card {
  position: relative;
  width: 100%;
  height: 82px;
  border-radius: 8px 0px 0px 0px;
   background-color: rgba(245, 252, 255, 1); 
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 10px;
  cursor: pointer;
  color:rgba(252, 128, 31, 1)
}

.Experience-card-title {
  color: rgba(0, 52, 102, 1);
  font-size: 20px;
  font-weight: 600;
 
}

.arrow-icon {
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: var(--Inks-Dark-64, rgba(0, 11, 37, 0.64));
} 

.arrow-open {
  transform: rotate(180deg);
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: var(--Inks-Dark-64, rgba(0, 11, 37, 0.64));
}

.oneSectionContainer {
  width: 100%;
}

.Details-container {
   background-color: rgb(220, 232, 245); 
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Details-container-arrow-title {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
}
.Experience-card-list ul {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.Experience-card-list {
  overflow-y: auto;
  max-height: 800px;
  padding-top: 20px;
  padding-bottom: 10px;
}

.Experience-card-list li::marker {
  color: rgba(0, 52, 102, 1);
  height: 14px;
  width: 14px;
}
.Experience-list-content {
  color: rgba(0, 11, 37, 0.64);
  font-size: 16px;
  font-weight: 400;
  text-align: justify;
  white-space: pre-wrap;
}
.div5 {
  overflow-y: scroll;
  min-height: 100vh;
  background-color: rgb(220, 232, 245);
}

@media (max-width: 810px) {
  .Experience-card-title {
    font-size: 15px;
    font-weight: 600;
  }
  .expSecondSection {
    margin: 50px;
  }
  .Experience-card-title {
    font-size: 10px;
    font-weight: 600;
  }
  .Experience-list-content {
    font-size: 10px;
  }
  .section-container {
    padding: 0px 0px;
  }
}
