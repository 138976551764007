.banner-container {
  width: 100%;
  height: 90vh;
}
.banner-Image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.IndustrialExp-container {
  display: flex;
  align-items: center;
  
}
.IndustrialExp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
.IndustrialExp-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
  position: relative;
  padding-left: 30px; /* Add padding to make space for the bullet */
}

.IndustrialExp-row::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  background: #FF5733; /* Primary bullet color */
  border: 2px solid ; /* Secondary outline color */
  border-radius: 50%; /* Make it a circle */
}


.IndustrialExp-item {
  flex: 1;
  text-align: left;
  font-size: 16px;
  color: rgba(52, 64, 84, 1);
}

.IndustrialExp-title {
  font-weight: 600;
  font-size: 18px;
}

.IndustrialExp-company {
 
  color: rgba(52, 64, 84, 0.8);
}

.IndustrialExp-period {
  text-align: right;
  font-size: 16px;
  color: rgba(0, 11, 37, 0.64);
}

@media (max-width: 850px) {
  .IndustrialExp-container-center {
    align-items: center;
  }
  .IndustrialExp-row {
    flex-direction: column;
    gap: 10px;
  }
  .IndustrialExp-period {
    text-align: left;
  }

  .banner-container {
    height: 45vh;
  }
}
