.ExpTitle-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
}
.ExpTitle_title {
  color: rgba(52, 64, 84, 1);
  font-size: 20px;
  font-weight: 500;
 
  
  
}

.ExpTitle_desc {
  color: rgba(0, 11, 37, 0.64);

  font-size: 20px;
  font-weight: 400;
  max-width: 500px;
}
@media (max-width: 1024px) {
  .ExpTitle_title {
      font-size: 15px;
font-weight: 600;

  } 
  .ExpTitle_desc {
  font-size: 15px;
}
}
