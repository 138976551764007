.navBar {
  background-color: #003366;
  height: 12vh;
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 328px;
  z-index: 999;
  color: white;
  font-size: 14px;
  
  
}

.navBar ul {
  display: flex;
  list-style-type: none;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
}

.navBar ul li {
  
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 400;
 
}

.Link_nav {
  text-decoration: none;
  color: white;
  
}

.Link_nav:hover {
  color: white;
  transition: color 0.3s;
  border-bottom: #ff5733 2px solid;
}

.menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  margin-top: 15px;
  left: 0px;
  background-color: #003366;
  border: 1px solid rgba(248, 248, 248, 1);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
  color: white;
  border-radius: 6%;
}
.dropdown {
  cursor: pointer;
  position: relative;
}
.dropdown-item1 {
  text-decoration: none;
  color: rgb(11, 18, 80);
}
.dropdown-menu li {
  padding: 10px 10px;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-item1:hover {
  
  color: rgb(11, 18, 80);
  transition: color 0.3s;
  border-bottom: #ff5733 2px solid;

}

.dropdown-menu {
  display: block !important;
}
.Link_nav.active,
.dropdown-item1.active {
  color: white;
  border-bottom: #ff5733 2px solid;
  font-weight: bold;
 
}
@media (max-width: 1300px) and (min-width: 1025px) {
  .navBar {
    gap: 100px;
  }

  .navBar ul li {
    padding: 8px 15px;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .navBar {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    position: fixed;
    top: 0;
  }

  .center_list {
    display: none;
    flex-direction: column;
    top: 96px;
    left: 0;
    width: 100%;
    background-color: #003366;
    align-items: center;
    min-height: 100vh;
    z-index: 10;
  }

  .center_list.open {
    display: flex;
    z-index: 10;
    position: fixed;
    
  }

  .navBar ul {
    flex-direction: column;
    gap: 20px;
  }

  .navBar ul li {
    width: 100%;
    text-align: center;
    background-color: transparent;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .download-btn {
    margin-top: 4px;
    width: 10vw;
  }
  .dropdown {
    text-align: center;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    width: 100%;
    margin-top: 0;
  }

  .dropdown-menu li {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .navBar {
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    position: fixed;
    top: 0;
  }

  .center_list {
    display: none;
    flex-direction: column;
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: #003366;
    align-items: center;
    min-height: 100vh;
    z-index: 10;
  }

  .center_list.open {
    display: flex;
  }

  .navBar ul {
    flex-direction: column;
    gap: 20px;
  }

  .navBar ul li {
    width: 100%;
    text-align: center;
    background-color: transparent;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .download-btn {
    margin-top: 4px;
    width: auto;
  }
  .dropdown-menu {
    position: relative;
  }
}
