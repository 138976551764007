.WorkTitle-Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:16px;
    text-align: center;
   
}
.WorkTitle_title{
   color: rgba(52, 64, 84, 1);
font-size: 35px;
font-weight: 600;




}

.WorkTitle_date{
color:rgba(0, 11, 37, 0.64);

font-size: 24px;
font-weight: 400;

}
@media (max-width: 1024px) {
    .WorkTitle_title{
        font-size: 15px;
font-weight: 600;

    } 
.WorkTitle_date{
    font-size: 15px;
}
}