.home{
  display: flex;
  gap:60px;
  flex-direction: column;
}
.My_projects_container {
  background-color: white;
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.countersContainer {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.counters-big-container{
  display: flex;
  flex-direction: column;
  gap:70px

}
.My_projects_text {
  text-align: center;
}
.MyExp_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 70vh;
  /* background-color: rgba(245, 252, 255, 1); */
  gap: 10px;
  margin-top: 10px;
}
.work_title_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 48px;
}

.seeMore {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(0, 113, 205, 1);
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-decoration: none;
  grid-column-start: 3;
  grid-column-end: 4;
  justify-self: flex-end;
}

.exp_title_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.Research-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  text-align: center;
  height: 100%;
}
.Main-upper-text {
  width: 100%;
}
.Research-container-cards {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}
.Awards-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  text-align: center;
  height: 100%;
}
.Awards-container-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.Training_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  text-align: center;
  height: 100%;
}

.Training-container-outer-circle {
  width: 70%;
  max-width: 700px;
  aspect-ratio: 1;
  background: conic-gradient(
    from 90deg,
    rgba(255, 235, 219, 0.2) -90deg,
    rgba(253, 111, 0, 0.3) 248.28deg,
    rgba(255, 235, 219, 0.2) 270deg,
    rgba(253, 111, 0, 0.3) 608.28deg
  );
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Training-container-inner-circle {
  width: 75%;
  height: 75%;
  background-color: white;
  border-radius: 50%;
}

.Training-container-box {
  width: 260px;
  height: 96px;
  background-color: rgba(79, 189, 210, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 31px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  position: absolute;
  box-shadow: 0px 4.29px 8.59px 0px rgba(0, 0, 0, 0.1);
}

.Training-container-box:nth-child(2) {
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.Training-container-box:nth-child(3) {
  top: 15%;
  left: -7%;
}
.Training-container-box:nth-child(4) {
  top: 40%;
  left: -6%;
  transform: translateY(-50%);
}
.Training-container-box:nth-child(5) {
  top: 60%;
  left: -5%;
  transform: translateY(-50%);
}
.Training-container-box:nth-child(6) {
  bottom: 15%;
  left: -4%;
}
.Training-container-box:nth-child(7) {
  bottom: -1%;
  left: 50%;
  transform: translateX(-50%);
}
.Training-container-box:nth-child(8) {
  bottom: 15%;
  right: -4%;
}
.Training-container-box:nth-child(9) {
  top: 40%;
  right: -6%;
  transform: translateY(-50%);
}
.Training-container-box:nth-child(10) {
  top: 60%;
  right: -5%;
  transform: translateY(-50%);
}
.Training-container-box:nth-child(11) {
  top: 15%;
  right: -7%;
}
.last-section{
  padding: 30px 0;
}

@media (max-width: 1024px) {
  .Training-container-box {
    position: static;
    margin: 10px 0;
    width: 100%;
    max-width: 300px;
  }
  .MyExp_small_container {
    gap: 10px;
  }
  .seeMore {
    font-size: 15px;
  }
  .Research-container-cards {
    flex-direction: column;
  }
  .Awards-container-cards {
    flex-direction: column;
  }
  .ExpTimeline {
    display: none;
  }
  .Training-container-outer-circle {
    width: 70%;
    height: 75%;
    aspect-ratio: 1;
    background: conic-gradient(
      from 90deg,
      rgba(255, 235, 219, 0.2) -90deg,
      rgba(253, 111, 0, 0.3) 248.28deg,
      rgba(255, 235, 219, 0.2) 270deg,
      rgba(253, 111, 0, 0.3) 608.28deg
    );
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .Training-container-inner-circle {
    width: 75%;
    height: 75%;
    background-color: white;
    border-radius: 50%;
  }

  .Training-container-box {
    width: 30%;
    height: 20%;
    font-size: 7px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    position: absolute;
    box-shadow: 0px 4.29px 8.59px 0px rgba(0, 0, 0, 0.1);
  }
  .Training-container-box:nth-child(2) {
    top: -2%;
    left: 50%;
    transform: translateX(-50%);
  }
  .Training-container-box:nth-child(3) {
    display: none;
  }
  .Training-container-box:nth-child(4) {
    top: 46%;
    left: -6%;
    transform: translateY(-50%);
  }
  .Training-container-box:nth-child(5) {
    display: none;
  }
  .Training-container-box:nth-child(6) {
    display: none;
  }
  .Training-container-box:nth-child(7) {
    bottom: -2%;
    left: 50%;
    transform: translateX(-50%);
  }
  .Training-container-box:nth-child(8) {
    display: none;
  }
  .Training-container-box:nth-child(9) {
    top: 46%;
    right: -6%;
    transform: translateY(-50%);
  }
  .Training-container-box:nth-child(10) {
    display: none;
  }
  .Training-container-box:nth-child(11) {
    display: none;
  }
}
